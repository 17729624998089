
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiPencil, mdiDelete } from '@mdi/js'
import { useNotify } from '@/store'

import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { Rights } from '@/api/types/right'
import { useDeletePositionScenario, useGetPositionScenarios } from '@/api/positionsScenario'
import { PositionScenarioOutput } from '@/api/types/positionScenario'
import { dateDashNotationToDot } from '@/utils/convertDate'

export default defineComponent({
  name: 'PositionScenarioDialog',
  components: {
    AddEditPositionScenarioDialog: () =>
      import(
        '@/views/planning/views/workforcePlanning/components/positionScenario/AddEditPositionScenarioDialog.vue'
      ),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const {
      exec: getPositionScenarios,
      data: positionScenarios,
      isLoading: isLoadingPositionScenarios,
    } = useGetPositionScenarios()

    async function init() {
      await getPositionScenarios()
    }
    init()

    const isAddEditPositionScenarioDialogOpen = ref(false)

    function onCloseAddEditPositionScenarioDialog(): void {
      isAddEditPositionScenarioDialogOpen.value = false
    }

    const { deletePositionScenario } = useDeletePositionScenario()

    async function onClickDelete(positionsScenario: PositionScenarioOutput): Promise<void> {
      try {
        await deletePositionScenario(positionsScenario.id)

        addNotification({
          text: root.$t('planning.workforcePlanning.positionScenario.delete.message') as string,
          type: 'success',
        })

        init()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onDblClickRow(positionScenario: PositionScenarioOutput): void {
      root.$router.push({
        name: 'position-timeline',
        params: { id: String(positionScenario.id) },
      })
    }

    return reactive({
      icons: {
        mdiPencil,
        mdiDelete,
      },
      constants: {
        Rights,
      },
      state: {
        positionScenarios,
        isLoadingPositionScenarios,

        isAddEditPositionScenarioDialogOpen,
      },
      listeners: {
        onCloseAddEditPositionScenarioDialog,

        onClickDelete,

        onDblClickRow,
      },
      functions: {
        hasSufficientRights,
        init,
        dateDashNotationToDot,
      },
    })
  },
})
