var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "value": _vm.value,
      "persistent": "",
      "width": "1000"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "90vh"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionScenario.title')) + " "), _c('v-spacer'), _vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_SCENARIO_CREATE) ? _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isAddEditPositionScenarioDialogOpen = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionScenario.form.add.title')) + " ")]) : _vm._e()], 1), _c('v-card-text', {
    staticClass: "my-5 content__height"
  }, [!_vm.state.isLoadingPositionScenarios && _vm.state.positionScenarios.length ? _c('v-simple-table', [_c('thead', [_c('tr', [_c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionScenario.form.id")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionScenario.form.title")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionScenario.form.description")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionScenario.form.start")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionScenario.form.end")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionScenario.form.createdAt")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  })])]), _c('tbody', _vm._l(_vm.state.positionScenarios, function (positionScenario) {
    return _c('tr', {
      key: positionScenario.id,
      staticClass: "table-row-color",
      on: {
        "dblclick": function dblclick($event) {
          return _vm.listeners.onDblClickRow(positionScenario);
        }
      }
    }, [_c('td', [_vm._v(" " + _vm._s(positionScenario.id) + " ")]), _c('td', [_vm._v(" " + _vm._s(positionScenario.title) + " ")]), _c('td', [_vm._v(" " + _vm._s(positionScenario.description) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(positionScenario.start)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(positionScenario.end)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(positionScenario.createdAt)) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_c('v-menu', {
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_SCENARIO_DELETE) ? _c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": "",
              "x-small": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1) : _vm._e()];
        }
      }], null, true)
    }, [_c('v-card', {
      attrs: {
        "width": "400px"
      }
    }, [_c('v-card-title', {
      staticClass: "pb-0 text-subtitle-2 break-word"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionScenario.delete.text')) + " ")]), _c('v-card-actions', [_c('v-btn', {
      staticClass: "rounded-lg",
      attrs: {
        "small": "",
        "outlined": "",
        "elevation": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickDelete(positionScenario);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.yes')) + " ")]), _c('v-spacer'), _c('v-btn', {
      staticClass: "rounded-lg",
      attrs: {
        "small": "",
        "outlined": "",
        "elevation": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.no')) + " ")])], 1)], 1)], 1)], 1)]);
  }), 0)]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionScenario.noPositionScenarios')) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.close')) + " ")])], 1)], 1), _vm.state.isAddEditPositionScenarioDialogOpen ? _c('AddEditPositionScenarioDialog', {
    on: {
      "reload-position-scenarios": function reloadPositionScenarios($event) {
        return _vm.functions.init();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditPositionScenarioDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditPositionScenarioDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditPositionScenarioDialogOpen", $$v);
      },
      expression: "state.isAddEditPositionScenarioDialogOpen"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }